import storageAdapter from 'src/helpers/storage.js'
const logoutEvent = 'logout'

const sessionService = {
  getSession: () => {
    return storageAdapter.getItem('accessToken')
  },
  getRefresh: () => {
    return storageAdapter.getItem('refreshToken')
  },
  setSession: (accessToken) => {
    storageAdapter.setItem('accessToken', accessToken)
  },
  setRefresh: (refreshToken) => {
    storageAdapter.setItem('refreshToken', refreshToken)
  },
  setKey: (key, value) => {
    storageAdapter.setItem(key, value)
  },
  getKey: (key) => {
    return storageAdapter.getItem(key)
  },
  logout: () => {
    storageAdapter.clear()
    const logOutEvent = new CustomEvent(logoutEvent)
    document.dispatchEvent(logOutEvent)
  },
}

export { sessionService, logoutEvent }

import APISettings from './auth.config.js'

import axios from 'axios'
import { sessionService } from './session.service'

const routePrefix = 'auth'
const instance = axios.create({
  baseURL: [APISettings.baseURL, routePrefix].join('/'),
})

const AuthAPI = {
  login: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/login`,
      data: model,
    })
    return response.data
  },
  logout: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/logout`,
      data: model,
      headers: {
        Authorization: `Bearer ${sessionService.getSession()}`,
      },
    })
    return response.data
  },
  refreshTokens: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/refreshtoken`,
      data: model,
    })
    return response.data
  },
  getVersion: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/version/`,
    })
    return response.data
  },
}

export default AuthAPI

import APISettings from './auth.config.js'
import AuthAPI from './auth.service'
import { sessionService } from './session.service'
import axios from 'axios'
import storageAdapter from 'src/helpers/storage.js'

axios.defaults.baseURL = APISettings.baseURL

const baseInstance = axios.create({})

baseInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers) config.headers = {}

    config.headers.Accept = 'application/json'
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json; charset=utf-8'
    }

    if (sessionService.getSession()) {
      config.headers.Authorization = `Bearer ${sessionService.getSession()}`
    } else {
      throw new axios.Cancel('No access token')
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

baseInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error)
    }

    // console.log(error)
    return AuthAPI.refreshTokens({
      refreshToken: sessionService.getRefresh(),
    })
      .then((res) => {
        sessionService.setSession(res.accessToken)

        if (res.accessToken) {
          error.response.config.headers['Authorization'] = 'Bearer ' + res.accessToken
        }

        return axios(error.response.config)
      })
      .catch(async (error) => {
        storageAdapter.clear()
        return Promise.reject(error)
      })
  },
)

export default baseInstance

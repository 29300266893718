import React from 'react'
import PropTypes from 'prop-types'
import {
  CForm,
  CFormLabel,
  CRow,
  CCol,
  CFormSelect,
  CFormControl,
  CButton,
  CSpinner,
  CTooltip,
} from '@coreui/react'
import validator from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import LicenseService from '../../../services/license.service'
import SystemSettingsService from '../../../services/systemsettings.service'
import AppSpinner from '../../AppSpinner'
const systemSettingsEvent = 'systemSettingsChanged'

class SystemGeneral extends React.Component {
  constructor() {
    super()

    let organizationSettingsTable = {
      TEST_EMAIL_SEND_PERIOD: 'testEmailSendPeriod',
      GENERAL_ALERT: 'generalAlert',
      ICON_FONT: 'iconFont',
      MARKER_NAME: 'markerName',
      SAMPLING_ON: 'samplingOn',
    }

    let organizationSettings = {}
    for (const [key, value] of Object.entries(organizationSettingsTable)) {
      organizationSettings[value] = null
    }

    this.state = {
      isLoading: true,
      requestInProgress: false,
      organizationSettingsTable,
      organizationSettings,
      general: {
        csvFileSeparator: '',
        decimalFileSeparator: '',
      },
      operationStatus: null,
      operationStatusClass: null,
      selectedFiles: null,
    }
  }

  decimalFileSeparatorArray = [
    { label: 'kropka', value: '.' },
    { label: 'przecinek', value: ',' },
  ]

  selectableBasic = [
    {
      label: 'nie',
      value: false,
    },
    {
      label: 'tak',
      value: true,
    },
  ]

  settings = []

  createLogoImage = async (fileImg, imgType) => {
    // create a blob
    let url = `data:${imgType};base64,${fileImg}`
    return fetch(url)
      .then((res) => res.blob())
      .then((myBlob) => {
        let imageURL = URL.createObjectURL(myBlob)
        let url = imageURL
        this.setState({ url })
      })
  }

  onChange(el, event, organizationSetting) {
    if (el.validationConditionPresent) {
      if (el.validationCondition(event.target.value)) {
        el.setErrorMessage = true
        el.className = 'is-invalid'
      } else {
        el.setErrorMessage = false
        el.className = 'is-valid'
      }
    }
    if (organizationSetting) {
      let organizationSettings = this.state.organizationSettings
      organizationSettings[el.name] =
        el.name === `${this.state.organizationSettingsTable.TEST_EMAIL_SEND_PERIOD}`
          ? parseInt(event.target.value)
          : event.target.value
      // organizationSettings[el.name] = event.target.value
      this.setState({ organizationSettings })
    } else {
      let general = this.state.general
      general[el.name] =
        el.name === 'paginationLimit' ? parseInt(event.target.value) : event.target.value
      // general[el.name] = event.target.value

      this.setState({ general })
    }
  }

  changeHandler = (event) => {
    this.setState({ selectedFiles: event.target.files, isSelected: true })
  }

  onSubmit = async (event) => {
    this.setState({ requestInProgress: true })
    event.preventDefault()
    const systemSettingsChangedEvent = new CustomEvent(systemSettingsEvent)

    try {
      const formData = new FormData()
      if (this.state.selectedFiles !== null) {
        const arrayToBePassed = Object.values(this.state.selectedFiles)
        if (arrayToBePassed.length === 1) {
          arrayToBePassed.map((el) => formData.append('logo', el))
        }
      }
      if (
        !Number.isInteger(this.state.general.paginationLimit) ||
        Math.sign(this.state.general.paginationLimit) <= 0
      ) {
        this.setState({
          operationStatus: 'Liczba sensorów na stronę powinna być liczbą całkowitą dodatnią!',
          operationStatusClass: 'test-status-failure',
          requestInProgress: false,
        })
        return
      } else if (
        !Number.isInteger(
          this.state.organizationSettings[
            `${this.state.organizationSettingsTable.TEST_EMAIL_SEND_PERIOD}`
          ],
        ) ||
        Math.sign(
          this.state.organizationSettings[
            `${this.state.organizationSettingsTable.TEST_EMAIL_SEND_PERIOD}`
          ],
        ) <= 0
      ) {
        this.setState({
          operationStatus: 'Okres emaila testowego powinien być liczbą całkowitą dodatnią!',
          operationStatusClass: 'test-status-failure',
          requestInProgress: false,
        })
        return
      }
      const iconFont = this.state.organizationSettings[
        `${this.state.organizationSettingsTable.ICON_FONT}`
      ]
      if (iconFont !== '' && (Number(iconFont) <= 0 || isNaN(Number(iconFont)))) {
        this.setState({
          operationStatus: 'Rozmiar ikony powinien być liczbą > 0!',
          operationStatusClass: 'test-status-failure',
          requestInProgress: false,
        })
        return
      }

      let organizationSettingsState = { ...this.state.organizationSettings }
      if (
        this.state.organizationSettings[`${this.state.organizationSettingsTable.MARKER_NAME}`] ===
          undefined ||
        this.state.organizationSettings[`${this.state.organizationSettingsTable.MARKER_NAME}`] ===
          null
      ) {
        // when the dropdown was not changed
        organizationSettingsState[`${this.state.organizationSettingsTable.MARKER_NAME}`] = 'false'
      }

      if (
        this.state.organizationSettings[`${this.state.organizationSettingsTable.SAMPLING_ON}`] ===
          undefined ||
        this.state.organizationSettings[`${this.state.organizationSettingsTable.SAMPLING_ON}`] ===
          null
      ) {
        // when the dropdown was not changed
        organizationSettingsState[`${this.state.organizationSettingsTable.SAMPLING_ON}`] = 'false'
      }
      let general = this.state.general
      let organizationSettings = organizationSettingsState
      let orgSetData = []
      for (const [key, value] of Object.entries(this.state.organizationSettingsTable)) {
        orgSetData.push({ name: value, value: organizationSettings[value], enabled: true })
      }
      formData.append('data', JSON.stringify(general))
      formData.append('orgSetData', JSON.stringify(orgSetData))
      const saveGeneralSettings = await SystemSettingsService.saveGeneral(formData)
      this.setState({
        organizationSettings: organizationSettingsState,
        operationStatus: saveGeneralSettings.status,
        operationStatusClass:
          saveGeneralSettings.result === 'failure' ? 'test-status-failure' : 'test-status-success',
        requestInProgress: false,
        general,
      })
      await this.load()
      document.dispatchEvent(systemSettingsChangedEvent)
    } catch (e) {
      this.setState({
        operationStatus: e,
        operationStatusClass: 'test-status-failure',
        requestInProgress: false,
      })
    }
  }

  onCancel = async () => {
    this.setState({ operationStatus: '', operationStatusClass: '' })
    await this.load()
  }

  render() {
    return this.state.isLoading ? (
      <AppSpinner />
    ) : (
      <>
        <hr></hr>
        <>
          <p>
            <b>Ustawienia ogólne</b>
          </p>
          <CForm
            className="custom-form"
            onSubmit={(event) => this.onSubmit(event)}
            encType="multipart/form-data"
          >
            {this.settings.map((set, index) => {
              return (
                <div key={index}>
                  <CRow>
                    <CCol>
                      <p>
                        <b>{set.heading}</b>
                      </p>
                      <CRow className="align-items-center d-flex">
                        <CCol xl={2} md={3} sm={4}>
                          <CFormLabel className="col-form-label">
                            <p style={{ margin: '0' }}>{set.formLabel}</p>
                          </CFormLabel>
                        </CCol>
                        <CCol md={9}>
                          <CRow className="align-items-baseline">
                            <CCol
                              md={4}
                              style={{ alignSelf: set.type === 'file' ? 'center' : 'baseline' }}
                            >
                              {/* For selectable change */}
                              {/* {set.html && set.selectable !== this.selectableBasic ? ( */}
                              {set.html ? (
                                <set.html
                                  value={
                                    set.organizationSetting
                                      ? this.state.organizationSettings[set.name]
                                      : this.state.general[set.name]
                                  }
                                  onChange={(event) =>
                                    this.onChange(set, event, set.organizationSetting)
                                  }
                                  disabled={
                                    this.state.submitInProgress || this.state.requestInProgress
                                  }
                                  className="w-100"
                                  required={true}
                                >
                                  {set.selectable.map((item, index) => {
                                    return (
                                      <option key={index} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </set.html>
                              ) : (
                                // ) : set.html && set.selectable === this.selectableBasic ? (
                                //   // TODO- use it: https://www.npmjs.com/package/react-switch
                                //   <div key={index} className="form-check form-switch">
                                //     <input
                                //       className="form-check-input"
                                //       type="checkbox"
                                //       role="switch"
                                //       id="flexSwitchCheckDefault"
                                //     />
                                //     <label
                                //       className="form-check-label"
                                //       htmlFor="flexSwitchCheckDefault"
                                //     />
                                //   </div>
                                <div className="d-flex align-items-center">
                                  <CFormControl
                                    component={'input'}
                                    className={set.className}
                                    type={set.type ? set.type : 'text'}
                                    autoComplete="new-password"
                                    name={set.inputName}
                                    value={
                                      set.organizationSetting
                                        ? !!this.state.organizationSettings
                                          ? this.state.organizationSettings[set.name]
                                          : ''
                                        : this.state.general[set.name]
                                    }
                                    onChange={
                                      set.type === 'file'
                                        ? (event) => this.changeHandler(event)
                                        : (event) => {
                                            this.onChange(set, event, set.organizationSetting)
                                          }
                                    }
                                    maxLength={set.maxLength ? set.maxLength : 400}
                                    required={set.required}
                                    disabled={
                                      this.state.submitInProgress ||
                                      this.state.requestInProgress ||
                                      set.checkValue
                                        ? !this.state[set.checkValue]
                                        : false
                                    }
                                  ></CFormControl>
                                </div>
                              )}
                            </CCol>
                            {set.description ? (
                              <CCol md={1}>
                                {/* <div className="custom-form-description">
                                <p>{set.description}</p>
                              </div> */}
                                <div className="status-and-thresholds">
                                  <span className="ms-1">
                                    <CTooltip content={set.description} placement="bottom">
                                      <div>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                      </div>
                                    </CTooltip>
                                  </span>
                                </div>
                              </CCol>
                            ) : (
                              <></>
                            )}

                            <CCol md={8} className={set.additionalFieldPossible ? 'd-none' : ''}>
                              <div
                                className={`${set.setErrorMessage ? 'active-error' : 'd-md-none'}`}
                              >
                                {set.setErrorMessage ? set.errorMessage : ''}
                              </div>
                            </CCol>
                            {set.additionalFieldPossible ? (
                              <CCol md={4}>
                                {this.state[set.checkValue] ? (
                                  <CRow className="align-items-center">
                                    <div className="w-100"></div>
                                    {/* <CCol>
                                      <CFormLabel xl={2} md={3} className="col-form-label">
                                        {this.state[set.paragraphValue]}
                                      </CFormLabel>
                                    </CCol> */}
                                    <CCol xs={12}>
                                      <div className="d-flex justify-content-center">
                                        <img
                                          src={this.state[set.srcState]}
                                          className="logo"
                                          alt=""
                                          style={{
                                            maxWidth: '160px',
                                          }}
                                        />
                                      </div>
                                    </CCol>
                                    {set.additionalButton && this.state.logoParagraph !== null ? (
                                      <CCol xs={12}>
                                        <CButton
                                          onClick={() => set.additionalButton.onClickFunction()}
                                        >
                                          {set.additionalButton.label}
                                        </CButton>
                                      </CCol>
                                    ) : (
                                      <></>
                                    )}
                                  </CRow>
                                ) : (
                                  <></>
                                )}
                              </CCol>
                            ) : (
                              <></>
                            )}
                          </CRow>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </div>
              )
            })}
            <CRow>
              <CCol md={3} sm={6}>
                <CButton
                  color="primary"
                  className="btn-md-left-sm-top"
                  type="submit"
                  disabled={this.state.requestInProgress}
                >
                  Zapisz ustawienia
                </CButton>
              </CCol>
              <CCol sm={6} md={3}>
                <CButton
                  color="primary"
                  className="btn-md-righ-sm-bottom"
                  type="button"
                  onClick={() => this.onCancel()}
                  disabled={this.state.requestInProgress}
                >
                  Anuluj
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol md={6}>
                {this.state.requestInProgress ? (
                  <CSpinner />
                ) : (
                  <div className={`${this.state.operationStatusClass} status`}>
                    <p>{this.state.operationStatus}</p>
                  </div>
                )}
              </CCol>
            </CRow>
          </CForm>
        </>
      </>
    )
  }

  load = async () => {
    if (this.props.organization != null) {
      const orgDetails = await LicenseService.refreshOrganizationDetails()
      const general = await SystemSettingsService.generalSettings()
      const organizationSettings = await SystemSettingsService.organizationSettingsSettings()

      if (orgDetails.licenseLogo) {
        let logoParagraph
        if (!!orgDetails.orgDetails.logo && !!general.data.extension) {
          logoParagraph = 'Wgrane logo'
          this.createLogoImage(orgDetails.orgDetails.logo, `image/${general.data.extension}`)
        } else {
          logoParagraph = null
        }
        this.setState({ isLogoInTheDB: true, logoParagraph })
      } else {
        this.setState({ isLogoInTheDB: false })
      }
      // if (
      //   this.props.orgDetails.orgDetails.logo !== null &&
      //   this.props.orgDetails.orgDetails.logoType
      // ) {
      //   this.setState({ isLogoInTheDB: true, logoParagraph: 'Wgrane logo: ' })
      //   this.createLogoImage(
      //     this.props.orgDetails.orgDetails.logo,
      //     this.props.orgDetails.orgDetails.logoType,
      //   )
      // } else {
      //   this.setState({ isLogoInTheDB: false, logoParagraph: 'Dotychczas nie wgrano logo' })
      // }

      this.setState(
        {
          general: !!general ? general.data : null,
          organizationSettings: !!organizationSettings ? organizationSettings.data : null,
          isLoading: false,
        },
        () => {
          this.settings = [
            {
              name: 'csvFileSeparator',
              heading: 'Import pliku csv:',
              setErrorMessage: false,
              errorMessage: 'Pole "Import pliku csv" nie może być puste!',
              validationConditionPresent: true,
              validationCondition: (val) => {
                return !val
              },
              formLabel: 'Separator danych:',
              required: true,
              maxLength: 1,
              organizationSetting: false,
              description: 'Definiuje rodzaj separatora przy odczycie danych z pliku csv.',
            },
            {
              name: 'decimalFileSeparator',
              heading: 'Eksport wartości do pliku csv:',
              formLabel: 'Separator dziesiętny (eksport):',
              html: CFormSelect,
              selectable: this.decimalFileSeparatorArray,
              organizationSetting: false,
              description:
                'Definiuje rodzaj separatora dziesietnego przy zapisie danych do pliku csv. Poprawie zadefiniowany ulatwi wczytywanie danych do np. arkuszy kalkulacyjnych do dalszej obrobki.',
            },
            {
              // https://apone.eu
              name: 'helpUrl',
              heading: 'Zakładka "Pomoc":',
              setErrorMessage: false,
              errorMessage: 'Błędny format URL!',
              validationConditionPresent: true,
              validationCondition: (val) => {
                return !val || !validator.isURL(val)
              },
              formLabel: 'Url:',
              required: true,
              organizationSetting: false,
              description:
                'Definiuje url, który otworzy się w nowej zakładce po naciśnięciu zakładki "Pomoc" w panelu bocznym.',
            },
            {
              name: 'generalAlert',
              // heading: 'Komunikat:',
              formLabel: 'Komunikat:',
              required: false,
              organizationSetting: true,
              description:
                'Wyświetlany do komunikacji z użytkownikami systemu, wyświetlany przy ikonach alarmu na górze.',
            },
            {
              name: 'paginationLimit',
              heading: 'Zakładka "Wskazania":',
              formLabel: 'Liczba sensorów na stronę:',
              setErrorMessage: false,
              errorMessage:
                'Pole "Liczba sensorów na stronę" nie może być mniejsze od 1, puste ani ułamkowe!',
              validationConditionPresent: true,
              validationCondition: (val) => {
                return !val || Number(val) < 1 || !Number.isInteger(Number(val))
              },
              className: '',
              required: true,
              type: 'number',
              organizationSetting: false,
              description: 'Definiuje, ile sensorów będzie widocznych w tabeli na jednej stronie.',
            },
            // TODOxTK make it generic (pass settings from BE and loop through it)
            // and add a type validation to ettings object
            {
              name: this.state.organizationSettingsTable.TEST_EMAIL_SEND_PERIOD,
              heading: 'Email testowy',
              formLabel: 'Okres emailu testowego:',
              setErrorMessage: false,
              errorMessage:
                'Pole "Okres emailu testowego" nie może być mniejsze od 0 ani ułamkowe!',
              validationConditionPresent: true,
              validationCondition: (val) => {
                return val.length !== 0 && (Number(val) < 0 || !Number.isInteger(Number(val)))
              },
              className: '',
              required: false,
              type: 'number',
              organizationSetting: true,
              description: 'Okres emailu testowego w GODZINACH',
            },
            {
              name: this.state.organizationSettingsTable.ICON_FONT,
              heading: 'Zakładka "Mapa":',
              setErrorMessage: false,
              type: 'number',
              errorMessage: 'Proszę wprowadzić liczbę > 0!',
              validationConditionPresent: true,
              validationCondition: (val) => {
                return val !== '' && (Number(val) <= 0 || isNaN(Number(val)))
              },
              formLabel: 'Rozmiar ikony (w px):',
              organizationSetting: true,
            },
            {
              name: this.state.organizationSettingsTable.MARKER_NAME,
              formLabel: 'Nazwa etykiety:',
              required: true,
              organizationSetting: true,
              html: CFormSelect,
              selectable: this.selectableBasic,
            },
            {
              name: this.state.organizationSettingsTable.SAMPLING_ON,
              heading: 'Zakładka "Przebiegi":',
              formLabel: 'Redukcja danych:',
              required: true,
              organizationSetting: true,
              html: CFormSelect,
              selectable: this.selectableBasic,
            },
            // logo upload
            {
              heading: 'Logo:',
              inputName: 'logo',
              formLabel: 'Wgraj logo:',
              type: 'file',
              required: false,
              additionalFieldPossible: true,
              checkValue: 'isLogoInTheDB',
              paragraphValue: 'logoParagraph',
              srcState: 'url',
              organizationSetting: false,
              additionalButton: {
                label: 'Usuń',
                onClickFunction: async () => {
                  const systemSettingsChangedEvent = new CustomEvent(systemSettingsEvent)
                  const data = {
                    logoUrl: this.state.general?.logoUrl,
                    extension: this.state.general?.extension,
                  }
                  await SystemSettingsService.deleteLogo(data)
                  this.setState({ url: null }, async () => {
                    await this.load()
                    document.dispatchEvent(systemSettingsChangedEvent)
                  })
                },
              },
            },
          ]
          // a workwround to re-render after setting settings?
          this.setState({ trigger: true })
        },
      )
    }
  }

  async componentDidMount() {
    setTimeout(async () => {
      await this.load()
    }, 0)
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.organization !== prevProps.organization ||
      this.props.orgDetails.orgDetails.logo !== prevProps.orgDetails.orgDetails.logo
    ) {
      this.setState({ isLoading: true, operationStatus: null })
      setTimeout(async () => {
        await this.load()
      }, 0)
    }
  }
}

SystemGeneral.propTypes = {
  organization: PropTypes.number,
  orgDetails: PropTypes.object,
}

export { systemSettingsEvent }
export default SystemGeneral
